import React from 'react';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Styled from './styles';

interface Props {
  panelId: string;
  expanded: boolean;
  panelTitle: string;
  onChange: () => void;
}

export const FaqPanel: React.FC<Props> = ({
  panelId,
  expanded,
  panelTitle,
  onChange,
  children,
  ...props
}) => {
  return (
    <Styled.ExpansionPanel square expanded={expanded} onChange={onChange}>
      <Styled.ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panelId}d-content`}
        id={`panel${panelId}d-header`}
        {...props}
      >
        <Typography>{panelTitle}</Typography>
      </Styled.ExpansionPanelSummary>
      <Styled.ExpansionPanelDetails>{children}</Styled.ExpansionPanelDetails>
    </Styled.ExpansionPanel>
  );
};
