import React from 'react';
import Hero from '~/components/Hero';
import { FaqPanel } from './FaqPanel';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useStringSet } from '~/state/hooks/useIndexSet';
import {
  P,
  Section,
  InternalLink,
  ExternalLink,
} from '~/components/BaseElements';

interface FAQTopic {
  title: string;
  questions: { title: string; answer: string }[];
}

export const Faq: React.FC = () => {
  const { t } = useTranslation();
  const [expandedQuestions, setExpandedQuestions] = useStringSet();

  const topics: FAQTopic[] = t(`FAQ.topics`, {
    returnObjects: true,
  });

  const onChange = (panelId: string) => () => setExpandedQuestions(panelId);

  return (
    <Section py={0}>
      <Hero isSubPage>{t('FAQ.title')}</Hero>
      <Box py={8}>
        <Container>
          <Grid container spacing={3} justifyContent="center">
            {topics.map((topic, topicIndex) => (
              <Grid item xs={12} md={8}>
                <Typography gutterBottom variant="h6">
                  {topic.title}
                </Typography>
                {topic.questions.map((question, questionIndex) => {
                  const panelId = `${topicIndex}-${questionIndex}`;
                  return (
                    <FaqPanel
                      panelId={panelId}
                      onChange={onChange(panelId)}
                      expanded={expandedQuestions.has(panelId)}
                      panelTitle={question.title}
                    >
                      <P>
                        <Trans
                          i18nKey={`FAQ.topics.${topicIndex}.questions.${questionIndex}.answer`}
                          components={{
                            internalLink: <InternalLink />,
                            externalLink: <ExternalLink />,
                          }}
                        />
                      </P>
                    </FaqPanel>
                  );
                })}
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            style={{ paddingTop: '2rem' }}
          >
            <Grid item xs={12} md={8}>
              <Typography align="center">
                {t('FAQ.contactUs.text')}
                <ExternalLink to={t('FAQ.contactUs.email')}>
                  {t('FAQ.contactUs.linkText')}
                </ExternalLink>{' '}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Section>
  );
};
