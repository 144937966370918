import { withStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    athensGray: string;
  }
  interface TypeText {
    blue: string;
  }
}

export const ExpansionPanel = withStyles(theme => ({
  root: {
    border: `1px solid ${alpha(theme.palette.common.black, 0.125)}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.athensGray,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.125)}`,
    marginBottom: -1,
    minHeight: 56,
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
    root: {
      color: theme.palette.text.blue,
    },
  },
}))(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
}))(MuiExpansionPanelDetails);
