import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { Faq } from '~/components/Faq';

const FaqPage: React.FC<PageProps> = props => (
  <>
    <SEO title="FAQ.title" pageContext={props.pageContext} />
    <Faq />
  </>
);

export default FaqPage;
